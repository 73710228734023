/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, useTemplate, image, path, jsonld }) {
	const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    siteUrl,
                    image,
                    author
                }
            }
        }
    `);

	const metaTitle = title ? title : site.siteMetadata.title;
	const metaTitleTemplate = title && useTemplate ? `%s | ${site.siteMetadata.title}` : null;

	const metaDescription = description || site.siteMetadata.description;
	const metaImage = site.siteMetadata.siteUrl + (image || site.siteMetadata.image);
	const metaUrl = path ? site.siteMetadata.siteUrl + path : null;

	const link = [];

	if (metaUrl) {
		link.push(
			{
				rel: 'canonical',
				href: metaUrl
			}
		);
	}

	const script = [];

	if (jsonld) {
		for (let i = 0; i < jsonld.length; i++) {
			script.push(
				{
					type: 'application/ld+json',
					innerHTML: JSON.stringify(jsonld[i])
				}
			);
		}
	}

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={metaTitle}
			titleTemplate={metaTitleTemplate}
			meta={[
				{
					name: 'description',
					content: metaDescription,
				},
				{
					property: 'og:title',
					content: title,
				},
				{
					property: 'og:description',
					content: metaDescription,
				},
				{
					property: 'og:image',
					content: metaImage,
				},
				{
					property: 'og:image:width',
					content: '1200',
				},
				{
					property: 'og:image:height',
					content: '630',
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:url',
					content: metaUrl,
				},
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:creator',
					content: site.siteMetadata.author,
				},
				{
					name: 'twitter:title',
					content: title,
				},
				{
					name: 'twitter:description',
					content: metaDescription,
				},
			].concat(meta)}
			link={link}
			script={script}
		/>
	);
}

SEO.defaultProps = {
	lang: 'en',
	meta: [],
	description: '',
	useTemplate: true
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
	image: PropTypes.string,
	path: PropTypes.string,
	useTemplate: PropTypes.bool,
	jsonld: PropTypes.arrayOf(PropTypes.object)
};

export default SEO;
